import { RefreshProvider } from "src/contexts/refreshContext";
import { PaymentConditionProvider } from "src/contexts/paymentConditionContext";
import { CompanyProvider } from "src/contexts/companyContext";
import { NetworkProvider } from "src/contexts/networkContext";
import { ProductCategoryProvider } from "src/contexts/productCategoryContext";
import { ProductCompanyProvider } from "src/contexts/productCompanyContext";
import { ProductProvider } from "src/contexts/productsContext";
import { UserProvider } from "src/contexts/userContext";
import { AdressProvider } from "src/contexts/addressContext";
import { ShoppingProcessProvider } from "src/contexts/shoppingProcessContext";
import { ShoppingProcessTypeProvider } from "src/contexts/shoppingProcessTypeContext";
import { CompanyContactProvider } from "src/contexts/companyContactContext";
import { ShoppingProcessItemProvider } from "src/contexts/shoppingProcessItemContext";
import { UnitProvider } from "src/contexts/unitContext";
import { ShoppingProcessPhasesProvider } from "src/contexts/shoppingProcessPhasesContext";
import { RateProvider } from "src/contexts/rateContext";
import { ShoppingProcessPhasesItemProvider } from "src/contexts/shoppingProcessPhasesItemContext";
import { ShoppingProccessStatusProvider } from "src/contexts/shoppingProccessStatusContext";
import { RequestProvider } from "src/contexts/requestContext";
import { RequestStatusProvider } from "src/contexts/requestStatusContext";
import { ProductCategoryGeneralProvider } from "src/contexts/productCategoryGeneralContext";
import { ShoppingProccessRateProvider } from "src/contexts/shoppingProccessRateContext";
import { RequestItemProvider } from "src/contexts/requestItemContext";
import { ImportProvider } from "src/contexts/ImportContext";
import { TableProvider } from "src/contexts/tableContext";
import { RatePCItemProvider } from "src/contexts/ratePCItemContext";
import { RoutineProvider } from "src/contexts/routinesContext";
import { FromToProductsProvider } from "src/contexts/fromToProductsContext";
import { FromToCategoryProvider } from "src/contexts/fromToCategoryContext";
import { InvoicesProvider } from "src/contexts/invoicesContext";
import { InvoiceItemsProvider } from "src/contexts/invoiceItemsContext";
import { InvoicingProvider } from "src/contexts/invoicingContext";
import { DocumentsProvider } from "src/contexts/documentsContext";
import { ShoppingProccessDemandProvider } from "src/contexts/shoppingProccessDemandContext";
import { ParticipatingCompaniesProvider } from "src/contexts/ParticipatingCompaniesContext";
import { DemandCollectionFormProvider } from "src/contexts/demandCollectionFormContext";
import { ShoppingProcessDemandClientProvider } from "src/contexts/shoppingProcessDemandClientContext";

const Providers = ({ children }: { children: any }) => {
  return (
    <TableProvider>
      <DemandCollectionFormProvider>
        <ShoppingProcessDemandClientProvider>
          <RefreshProvider>
            <DocumentsProvider>
              <CompanyContactProvider>
                <AdressProvider>
                  <CompanyProvider>
                    <UserProvider>
                      <ImportProvider>
                        <ProductCategoryGeneralProvider>
                          <UnitProvider>
                            <NetworkProvider>
                              <RateProvider>
                                <CompanyContactProvider>
                                  <PaymentConditionProvider>
                                    <ProductCategoryProvider>
                                      <ProductProvider>
                                        <ProductCompanyProvider>
                                          <AdressProvider>
                                            <ShoppingProccessDemandProvider>
                                              <ShoppingProccessStatusProvider>
                                                <RequestStatusProvider>
                                                  <ShoppingProcessTypeProvider>
                                                    <ShoppingProcessItemProvider>
                                                      <ShoppingProccessRateProvider>
                                                        <RatePCItemProvider>
                                                          <ShoppingProcessProvider>
                                                            <ImportProvider>
                                                              <ParticipatingCompaniesProvider>
                                                                <FromToProductsProvider>
                                                                  <FromToCategoryProvider>
                                                                    <RoutineProvider>
                                                                      <ShoppingProccessRateProvider>
                                                                        <RequestProvider>
                                                                          <RequestItemProvider>
                                                                            <InvoicesProvider>
                                                                              <InvoiceItemsProvider>
                                                                                <ShoppingProcessItemProvider>
                                                                                  <ShoppingProcessPhasesProvider>
                                                                                    <ShoppingProcessPhasesItemProvider>
                                                                                      <ShoppingProcessPhasesProvider>
                                                                                        <InvoicingProvider>
                                                                                          <DemandCollectionFormProvider>
                                                                                            {
                                                                                              children
                                                                                            }
                                                                                          </DemandCollectionFormProvider>
                                                                                        </InvoicingProvider>
                                                                                      </ShoppingProcessPhasesProvider>
                                                                                    </ShoppingProcessPhasesItemProvider>
                                                                                  </ShoppingProcessPhasesProvider>
                                                                                </ShoppingProcessItemProvider>
                                                                              </InvoiceItemsProvider>
                                                                            </InvoicesProvider>
                                                                          </RequestItemProvider>
                                                                        </RequestProvider>
                                                                      </ShoppingProccessRateProvider>
                                                                    </RoutineProvider>
                                                                  </FromToCategoryProvider>
                                                                </FromToProductsProvider>
                                                              </ParticipatingCompaniesProvider>
                                                            </ImportProvider>
                                                          </ShoppingProcessProvider>
                                                        </RatePCItemProvider>
                                                      </ShoppingProccessRateProvider>
                                                    </ShoppingProcessItemProvider>
                                                  </ShoppingProcessTypeProvider>
                                                </RequestStatusProvider>
                                              </ShoppingProccessStatusProvider>
                                            </ShoppingProccessDemandProvider>
                                          </AdressProvider>
                                        </ProductCompanyProvider>
                                      </ProductProvider>
                                    </ProductCategoryProvider>
                                  </PaymentConditionProvider>
                                </CompanyContactProvider>
                              </RateProvider>
                            </NetworkProvider>
                          </UnitProvider>
                        </ProductCategoryGeneralProvider>
                      </ImportProvider>
                    </UserProvider>
                  </CompanyProvider>
                </AdressProvider>
              </CompanyContactProvider>
            </DocumentsProvider>
          </RefreshProvider>
        </ShoppingProcessDemandClientProvider>
      </DemandCollectionFormProvider>
    </TableProvider>
  );
};

export default Providers;
