/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Collapse,
  Paper,
  Container,
  Autocomplete,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { palette, theme } from "src/theme";
import { FormValues } from "src/contexts/demandCollectionFormContext";
import useDemandCollectionForm from "src/hooks/useDemandCollectionForm";
import { useEffect, useRef } from "react";
import CircularLoading from "src/components/Loading";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation, useParams } from "react-router-dom";
import useShoppingProccessDemandClient from "src/hooks/useShoppingProcessDemandClient";
import { formatCurrency } from "src/functions/text";
import useProductCompany from "src/hooks/useProductCompany";
import translations from "src/_i18n/translations.json";
import Swal from "sweetalert2";
import { tLanguage } from "src/types/language";
import { ToastContainer } from "react-toastify";

function DemandCollectionForm() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const processocompraid = queryParams.get("processocompraid");
  const empresaid = queryParams.get("empresaid");

  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    isMobile,
    handleDrawerToggle,
    selectedTab,
    handleTabChange,
    drawerOpen,
    isLargeScreen,
    handleInputChange,
    handleToggle,
    open,
    handleAddSection,
    indicacaoItensSections,
    handleRemoveSection,
    send,
    setItemSections,
    itemSections,
    type,
    getType,
    handleFileUpload,
    gererateCSV,
  } = useDemandCollectionForm();

  const { getShoppingProcessDemandClient, loading, isError, demand } =
    useShoppingProccessDemandClient();

  const {
    getProducts,
    productsToSearch,
    setProductsToSearch,
    loading: loadingProduct,
  } = useProductCompany();

  useEffect(() => {
    if (processocompraid && empresaid) {
      started(processocompraid, empresaid);
      getType();
    }

    return () => {
      setProductsToSearch([]);
    };
  }, []);

  const handleButtonClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const started = async (processoCompraId: string, empresaId: string) => {
    const items = await getShoppingProcessDemandClient(
      processoCompraId,
      empresaId
    );
    getProducts(empresaId, 0, 9999);

    setItemSections(items);
  };

  const add = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: value?.id ?? "",
            codigoCliente: value?.code ?? "",
            descricaoCliente: value?.label ?? "",
          },
        },
      },
      index,
      "item"
    );
  };

  const remove = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: "",
            codigoCliente: "",
            descricaoCliente: "",
          },
        },
      },
      index,
      "item"
    );
  };

  const addSugestions = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: value?.id ?? "",
            codigoCliente: value?.code ?? "",
            descricaoCliente: value?.label ?? "",
          },
        },
      },
      index,
      "indicacaoItens"
    );
  };

  const removeSugestions = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            produtoEmpresaId: "",
            codigoCliente: "",
            descricaoCliente: "",
          },
        },
      },
      index,
      "indicacaoItens"
    );
  };

  if (isError) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
        <ErrorOutlineIcon sx={{ fontSize: 100, color: "primary.main" }} />
        <Typography variant="h3" sx={{ color: "primary.main", mt: 2 }}>
          400 - Ops, Não existe demanda cadastrada para este processo e esta
          empresa!
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", mt: 2 }}>
          Ocorreu um erro inesperado. Estamos trabalhando para corrigir o
          problema.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => window.location.reload()}
        >
          Tentar Novamente
        </Button>
      </Container>
    );
  }

  const drawer = (
    <div>
      <List>
        <ListItem sx={{ color: theme.palette.primary.main }}>
          <ListItemText primary="Demandas" />
        </ListItem>
        <ListItem sx={{ color: "#c6c6c6" }}>
          <ListItemText primary="Logística" />
        </ListItem>
        <ListItem sx={{ color: "#c6c6c6" }}>
          <ListItemText primary="Indicação Fornecedores" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ backgroundColor: "#fff" }}>
          {isMobile ? (
            <IconButton
              edge="start"
              color="primary"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { borderRadius: 4 } }}
            >
              <Tab
                label="Demandas"
                style={{
                  color:
                    selectedTab === 0 ? "#fff" : theme.palette.primary.main,
                  backgroundColor:
                    selectedTab === 0 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 0 ? "4px 4px 0 0" : 0,
                }}
              />
              <Tab
                label="Logística"
                disabled
                style={{
                  color: selectedTab === 1 ? "#fff" : "#c6c6c6",
                  backgroundColor:
                    selectedTab === 1 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 1 ? "4px 4px 0 0" : 0,
                }}
              />
              <Tab
                label="Indicação Fornecedores"
                disabled
                style={{
                  color: selectedTab === 2 ? "#fff" : "#c6c6c6",
                  backgroundColor:
                    selectedTab === 2 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 2 ? "4px 4px 0 0" : 0,
                }}
              />
            </Tabs>
          )}
        </Toolbar>
      </AppBar>

      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
        }}
      />

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>

      {loading || loadingProduct ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "5%" }}
        >
          <CircularLoading />
        </Box>
      ) : (
        <form onSubmit={(e) => send(e, demand, empresaid, type)}>
          <ToastContainer />
          <div style={{ padding: theme.spacing(2) }}>
            <Grid container spacing={2}>
              <Grid container item spacing={2}>
                <Grid item xs={12} md={6} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={2}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <strong>Descrição do Processo</strong>
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={10}>
                    <TextField
                      placeholder="Descrição do Processo"
                      fullWidth
                      size="small"
                      name="descricao"
                      InputProps={{ style: { fontWeight: "bold" } }}
                      disabled
                      value={demand?.descricao || ""}
                      onChange={(e) => handleInputChange(e, 0, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={2}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <strong>Número Processo</strong>
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={10}>
                    <TextField
                      placeholder="Número Processo"
                      fullWidth
                      size="small"
                      name="numero"
                      InputProps={{ style: { fontWeight: "bold" } }}
                      disabled
                      value={demand?.numero || ""}
                      onChange={(e) => handleInputChange(e, 1, "")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        CNPJ
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="CNPJ"
                      fullWidth
                      size="small"
                      name="cnpj"
                      disabled
                      value={demand?.cnpj || ""}
                      onChange={(e) => handleInputChange(e, 2, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Empresa
                      </Typography>
                    </Grid>
                  )}

                  <Grid xs={9}>
                    <TextField
                      placeholder="Empresa"
                      fullWidth
                      size="small"
                      name="razaoSocial"
                      disabled
                      value={demand?.razaoSocial || ""}
                      onChange={(e) => handleInputChange(e, 3, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Rede
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Rede"
                      fullWidth
                      size="small"
                      name="rede"
                      disabled
                      value={demand?.rede || ""}
                      onChange={(e) => handleInputChange(e, 4, "")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Comprador Responsável
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Comprador Responsável"
                      fullWidth
                      size="small"
                      name="nomeComprador"
                      disabled
                      value={demand?.nomeComprador || ""}
                      onChange={(e) => handleInputChange(e, 5, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        E-mail
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="E-mail"
                      fullWidth
                      size="small"
                      name="emailComprador"
                      disabled
                      value={demand?.emailComprador || ""}
                      onChange={(e) => handleInputChange(e, 6, "")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={3}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Telefone
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={9}>
                    <TextField
                      placeholder="Telefone"
                      fullWidth
                      size="small"
                      name="telefoneComprador"
                      disabled
                      value={demand?.telefoneComprador || ""}
                      onChange={(e) => handleInputChange(e, 7, "")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid xs={1}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Carta Convite
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={11}>
                    <TextField
                      placeholder="Carta Convite"
                      multiline
                      minRows={2}
                      fullWidth
                      size="small"
                      name="cartaConvite"
                      disabled
                      value={demand?.cartaConvite || ""}
                      onChange={(e) => handleInputChange(e, 8, "")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={theme.spacing(2)}
                marginBottom={theme.spacing(2)}
              >
                <Typography variant="h6">Itens</Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <input
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileUpload(e, demand)}
                    />
                    <Button
                      variant="contained"
                      startIcon={<UploadIcon />}
                      size="small"
                      onClick={() => {
                        Swal.fire({
                          title: translations[param]["attention"],
                          text: `A ação irá iniciar o upload do arquivo excel. deseja continuar ? `,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["yes"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleButtonClick();
                          }
                        });
                      }}
                    >
                      Upload
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    size="small"
                    color="secondary"
                    onClick={() => gererateCSV(demand)}
                    sx={{ marginLeft: 1 }}
                  >
                    Download Modelo
                  </Button>
                </Box>
              </Box>
              <Box>
                {!isMobile ? (
                  <Table component={Paper}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Cód.</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>
                          Cód. Cliente <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>
                          Descrição Cliente{" "}
                          <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>
                          Quantidade <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>
                          Valor Últ. Compra (R$){" "}
                          <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>
                          Valor Alvo (R$){" "}
                          <span style={{ color: "red" }}>*</span>
                        </TableCell>
                        <TableCell>Não Possui Interesse</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemSections?.map((item: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell>
                            {item?.codigo ?? "Não informado"}
                          </TableCell>
                          <TableCell>
                            {item?.descricao ?? "Não informado"}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "250px",
                              maxWidth: "400px",
                              minWidth: "250px",
                            }}
                          >
                            <Autocomplete
                              disablePortal
                              options={productsToSearch}
                              freeSolo
                              id="show_select_administrator_code"
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option;
                                }
                                return option?.code || option || "";
                              }}
                              value={
                                itemSections[index]?.codigoCliente
                                  ? productsToSearch.find(
                                      (product) =>
                                        product.code ===
                                        itemSections[index]?.codigoCliente
                                    ) || itemSections[index]?.codigoCliente
                                  : null
                              }
                              size="small"
                              onChange={(_, value) => {
                                if (value) {
                                  add(index, value);
                                } else {
                                  remove(index);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Selecione"
                                  size="small"
                                  required={
                                    itemSections &&
                                    itemSections[index]?.possuiInteresse
                                  }
                                  onChange={(e) => {
                                    add(index, {
                                      code: e.target.value,
                                      label:
                                        itemSections[index]?.descricaoCliente,
                                    });
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "250px",
                              maxWidth: "400px",
                              minWidth: "250px",
                            }}
                          >
                            <Autocomplete
                              disablePortal
                              freeSolo
                              id="show_select_administrator_company"
                              options={productsToSearch}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option;
                                }
                                return option?.label || "";
                              }}
                              size="small"
                              value={
                                itemSections[index]?.descricaoCliente
                                  ? productsToSearch.find(
                                      (product) =>
                                        product.label ===
                                        itemSections[index]?.descricaoCliente
                                    ) || itemSections[index]?.descricaoCliente
                                  : null
                              }
                              onChange={(_, value) => {
                                if (value && typeof value === "object") {
                                  add(index, value);
                                } else {
                                  remove(index);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Selecione"
                                  size="small"
                                  required={
                                    itemSections &&
                                    itemSections[index]?.possuiInteresse
                                  }
                                  onChange={(e) => {
                                    add(index, {
                                      label: e.target.value,
                                      code: itemSections[index]?.codigoCliente,
                                    });
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              placeholder="Quantidade"
                              fullWidth
                              size="small"
                              name="quantidade"
                              value={itemSections[index]?.quantidade}
                              required={
                                itemSections &&
                                itemSections[index]?.possuiInteresse
                              }
                              onChange={(e) =>
                                handleInputChange(e, index, "item")
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              placeholder="Valor Últ. Compra (R$)"
                              fullWidth
                              size="small"
                              name="valorUnitario"
                              value={itemSections[index]?.valorUnitario}
                              required={
                                itemSections &&
                                itemSections[index]?.possuiInteresse
                              }
                              onChange={(e) => {
                                e = formatCurrency(e);
                                handleInputChange(e, index, "item");
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              placeholder="Valor Alvo (R$)"
                              fullWidth
                              size="small"
                              name="valorAlvo"
                              value={itemSections[index]?.valorAlvo}
                              required={
                                itemSections &&
                                itemSections[index]?.possuiInteresse
                              }
                              onChange={(e) => {
                                e = formatCurrency(e);
                                handleInputChange(e, index, "item");
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              name="possuiInteresse"
                              checked={!itemSections[index]?.possuiInteresse}
                              onChange={(e) =>
                                handleInputChange(e, index, "item")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Box>
                    {itemSections?.map((item: any, index: any) => (
                      <Paper key={index} sx={{ marginBottom: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                          }}
                        >
                          <Typography variant="subtitle1">
                            {`Cód.: ${
                              item?.codigo ?? "Não informado"
                            } - Descrição: ${
                              item?.descricao ?? "Não informado"
                            }`}
                          </Typography>
                          <IconButton onClick={() => handleToggle(index)}>
                            {open[index] ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={open[index]}>
                          <Box sx={{ padding: 2 }}>
                            <Box>
                              <strong>Cód.:</strong>{" "}
                              {item?.codigo ?? "Não informado"}
                            </Box>
                            <Box>
                              <strong>Descrição:</strong>{" "}
                              {item?.descricao ?? "Não informado"}
                            </Box>
                            <Box>
                              <strong>Cód. Cliente:</strong>
                              <Autocomplete
                                disablePortal
                                options={productsToSearch}
                                freeSolo
                                id="show_select_administrator_code"
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  return option?.code || option || "";
                                }}
                                value={
                                  itemSections[index]?.codigoCliente
                                    ? productsToSearch.find(
                                        (product) =>
                                          product.code ===
                                          itemSections[index]?.codigoCliente
                                      ) || itemSections[index]?.codigoCliente
                                    : null
                                }
                                size="small"
                                onChange={(_, value) => {
                                  if (value) {
                                    add(index, value);
                                  } else {
                                    remove(index);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                    required={
                                      itemSections &&
                                      itemSections[index]?.possuiInteresse
                                    }
                                    onChange={(e) => {
                                      add(index, {
                                        code: e.target.value,
                                        label:
                                          itemSections[index]?.descricaoCliente,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </Box>
                            <Box>
                              <strong>Descrição Cliente:</strong>
                              <Autocomplete
                                disablePortal
                                freeSolo
                                id="show_select_administrator_company"
                                options={productsToSearch}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  return option?.label || "";
                                }}
                                size="small"
                                value={
                                  itemSections[index]?.descricaoCliente
                                    ? productsToSearch.find(
                                        (product) =>
                                          product.label ===
                                          itemSections[index]?.descricaoCliente
                                      ) || itemSections[index]?.descricaoCliente
                                    : null
                                }
                                onChange={(_, value) => {
                                  if (value && typeof value === "object") {
                                    add(index, value);
                                  } else {
                                    remove(index);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione"
                                    size="small"
                                    required={
                                      itemSections &&
                                      itemSections[index]?.possuiInteresse
                                    }
                                    onChange={(e) => {
                                      add(index, {
                                        label: e.target.value,
                                        code: itemSections[index]
                                          ?.codigoCliente,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </Box>
                            <Box>
                              <strong>
                                Quantidade
                                <span style={{ color: "red" }}>*</span>:
                              </strong>

                              <TextField
                                placeholder="Quantidade"
                                fullWidth
                                size="small"
                                name="quantidade"
                                value={itemSections[index]?.quantidade}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </Box>
                            <Box>
                              <strong>
                                Valor Últ. Compra (R$)
                                <span style={{ color: "red" }}>*</span>:
                              </strong>
                              <TextField
                                placeholder="Valor Últ. Compra (R$)"
                                fullWidth
                                size="small"
                                name="valorUnitario"
                                value={itemSections[index]?.valorUnitario}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </Box>
                            <Box>
                              <strong>
                                Valor Alvo (R$)
                                <span style={{ color: "red" }}>*</span>:
                              </strong>

                              <TextField
                                placeholder="Valor Alvo (R$)"
                                fullWidth
                                size="small"
                                name="valorAlvo"
                                value={itemSections[index]?.valorAlvo}
                                required={
                                  itemSections &&
                                  itemSections[index]?.possuiInteresse
                                }
                                onChange={(e) => {
                                  e = formatCurrency(e);
                                  handleInputChange(e, index, "item");
                                }}
                              />
                            </Box>
                            <Box>
                              <strong>Não Possui Interesse:</strong>{" "}
                              <Checkbox
                                name="possuiInteresse"
                                checked={!itemSections[index]?.possuiInteresse}
                                onChange={(e) =>
                                  handleInputChange(e, index, "item")
                                }
                              />
                            </Box>
                          </Box>
                        </Collapse>
                      </Paper>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>

            <Divider
              style={{
                marginTop: theme.spacing(4),
                backgroundColor: theme.palette.primary.main,
              }}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">Indicação de Itens</Typography>
                <IconButton
                  color="primary"
                  onClick={() => handleAddSection("indicacaoItens")}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>

            {indicacaoItensSections?.map((section: any, index: any) => (
              <Box key={index} marginBottom={theme.spacing(2)} display="flex">
                <Grid container spacing={2}>
                  {[
                    { label: "Cód.", name: "codigo" },
                    { label: "Descrição", name: "descricao" },
                    { label: "Quantidade *", name: "quantidade" },
                    {
                      label: "Valor Últ. Compra (R$) *",
                      name: "valorUnitario",
                    },
                    { label: "Valor Alvo (R$) *", name: "valorAlvo" },
                  ]?.map((field, idx) => (
                    <Grid item xs={12} sm={2} key={idx}>
                      {field.name === "codigo" && (
                        <Autocomplete
                          disablePortal
                          options={productsToSearch}
                          freeSolo
                          id="show_select_administrator_code"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            return option?.code || "";
                          }}
                          value={
                            indicacaoItensSections[index]?.codigoCliente
                              ? productsToSearch.find(
                                  (product) =>
                                    product.code ===
                                    indicacaoItensSections[index]?.codigoCliente
                                ) ||
                                indicacaoItensSections[index]?.codigoCliente
                              : null
                          }
                          size="small"
                          onChange={(_, value) => {
                            if (value) {
                              addSugestions(index, value);
                            } else {
                              removeSugestions(index);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecione"
                              size="small"
                              required
                              onChange={(e) => {
                                addSugestions(index, {
                                  code: e.target.value,
                                  label:
                                    indicacaoItensSections[index]
                                      ?.descricaoCliente,
                                });
                              }}
                            />
                          )}
                        />
                      )}
                      {field.name === "descricao" && (
                        <Autocomplete
                          disablePortal
                          freeSolo
                          id="show_select_administrator_company"
                          options={productsToSearch}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            return option?.label || "";
                          }}
                          size="small"
                          value={
                            indicacaoItensSections[index]?.descricaoCliente
                              ? productsToSearch.find(
                                  (product) =>
                                    product.label ===
                                    indicacaoItensSections[index]
                                      ?.descricaoCliente
                                ) ||
                                indicacaoItensSections[index]?.descricaoCliente
                              : null
                          }
                          onChange={(_, value) => {
                            if (value && typeof value === "object") {
                              addSugestions(index, value);
                            } else {
                              removeSugestions(index);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecione"
                              size="small"
                              required
                              onChange={(e) => {
                                addSugestions(index, {
                                  label: e.target.value,
                                  code: indicacaoItensSections[index]
                                    ?.codigoCliente,
                                });
                              }}
                            />
                          )}
                        />
                      )}
                      {field.name !== "codigo" &&
                        field.name !== "descricao" && (
                          <TextField
                            placeholder={field.label}
                            fullWidth
                            size="small"
                            required
                            name={field.name}
                            value={section[field.name as keyof FormValues]}
                            onChange={(e) => {
                              if (
                                e.target.name === "valorUnitario" ||
                                e.target.name === "valorAlvo"
                              ) {
                                e = formatCurrency(e);
                                handleInputChange(e, index, "indicacaoItens");
                              } else {
                                handleInputChange(e, index, "indicacaoItens");
                              }
                            }}
                          />
                        )}
                    </Grid>
                  ))}
                  <IconButton
                    onClick={() => handleRemoveSection(index, "indicacaoItens")}
                    style={{ marginTop: theme.spacing(1) }}
                  >
                    <RemoveCircleOutlineIcon color="error" />
                  </IconButton>
                </Grid>
              </Box>
            ))}

            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ marginTop: theme.spacing(4) }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<CancelIcon />}
                >
                  Declinar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<CheckIcon />}
                  type="submit"
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </div>
  );
}

export default DemandCollectionForm;
