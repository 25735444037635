/* eslint-disable react-hooks/exhaustive-deps */
import { palette } from "src/theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ItemCustom } from "src/components/ItemCustom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ButtonDefault,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import useLayout from "src/hooks/useLayout";
import DialogComponent from "src/components/Dialog";
import ItemStagePurchasingProcess from "./ItemStagePurchasingProcess";
import useShoppingProcessType from "src/hooks/useShoppingProcessType";
import { useEffect } from "react";
import useShoppingProcessPhases from "src/hooks/useShoppingProcessPhases";
import useShoppingProcessPhasesItem from "src/hooks/useShoppingProcessPhasesItem";
import useShoppingProccessStatus from "src/hooks/useShoppingProccessStatus";
import { MaskStyle } from "src/styles/globalStyle";
import Empty from "src/components/Empty";
import { format } from "date-fns";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import useTable from "src/hooks/useTable";
import useCompanyContact from "src/hooks/useCompanyContact";

const header = [
  {
    id: "code",
    label: "Código",
    isOrder: true,
    order: 1,
  },
  {
    id: "description",
    label: "Descrição",
    isOrder: true,
    order: 2,
  },
  {
    id: "buyer",
    label: "Comprador",
    isOrder: true,
    order: 3,
  },
  {
    id: "supplier",
    label: "Fornecedor",
    isOrder: true,
    order: 4,
  },
  {
    id: "pgto",
    label: "Cond. de Pgto",
    isOrder: true,
    order: 5,
  },
  {
    id: "unit",
    label: "Unidade",
    isOrder: true,
    order: 6,
  },
  {
    id: "quantity",
    label: "Quantidade",
    isOrder: true,
    order: 7,
  },
  {
    id: "unit_value",
    label: "Valor unitário",
    isOrder: true,
    order: 8,
  },
  {
    id: "total_value",
    label: "Valor total",
    isOrder: true,
    order: 9,
  },
];

const StagePurchasingProcess: React.FC = () => {
  const { setOpenDialog, openDialogSecondary, setOpenDialogSecondary } =
    useLayout();

  const { setFieldOrder, setOrder } = useTable();

  const {
    type,
    setType,
    status,
    setStatus,
    proccessNumber,
    setProccessNumber,
    description,
    setDescription,
    registerDate,
    setRegisterDate,
    endDate,
    setEndDate,
    shoppingProcesPhasesSelect,
    handleNewSalve,
    handleUpdate,
    handleSelected: handleSelectedShoppingProcessPhases,
    statusProgress,
    observationStatusPC,
    setObservationStatusPC,
    setShowButtonClosed,
    getShoppingProcessPhases,
  } = useShoppingProcessPhases();

  const {
    loadingDialog,

    shoppingProcesSelect,
  } = useShoppingProcess();

  const { loading: loadingShoppingProcessType, shoppingProcessPhaseType } =
    useShoppingProcessType();

  const { getShoppingProccessStatus, shoppingProccessStatus } =
    useShoppingProccessStatus();

  const {
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    shoppingProcessPhasesItems,
    setShoppingProcessPhasesItems,
    handleSelected,
    handleDelete,
    loading: loadingShoppingProcessPhasesItem,
    getShoppingProcessPhasesItems,
    setShoppingProcesPhasesItemSelect,
    sumValueTotalItems,
    orderBy,
    setOrderBy,
  } = useShoppingProcessPhasesItem();

  const { getCompanysContacts } = useCompanyContact();

  useEffect(() => {
    setFieldOrder({
      id: "description",
      label: "Descrição",
      isOrder: true,
      order: 2,
    });
    setOrder("desc");
  }, []);

  useEffect(() => {
    if (shoppingProcesPhasesSelect?.id)
      getShoppingProcessPhasesItems(shoppingProcesPhasesSelect.id);
  }, [page, orderBy]);

  useEffect(() => {
    getShoppingProccessStatus();
  }, [shoppingProcessPhasesItems]);

  const isLargeScreen = useMediaQuery("(min-width:1400px)");

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Etapa do processo de compra
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialog(false);
          handleSelectedShoppingProcessPhases(null);
          setShoppingProcessPhasesItems([]);
          getShoppingProcessPhases(`${shoppingProcesSelect?.id}`);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (!shoppingProcesPhasesSelect?.id) {
            handleNewSalve(shoppingProcesSelect?.id ?? "");
          } else {
            handleUpdate(
              shoppingProcesSelect?.id ?? "",
              sumValueTotalItems ?? 0
            );
          }
        }}
      >
        <DialogContent>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Processo de compra</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 10.5 : 12}>
                  <TextField
                    size="small"
                    required
                    disabled={true}
                    value={shoppingProcesSelect?.number}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Processo etapa</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 10.5 : 12}>
                  <TextField
                    size="small"
                    required
                    placeholder="Código do processo"
                    value={proccessNumber}
                    onChange={(e) => setProccessNumber(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Descrição</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 10.5 : 12}>
                  <TextField
                    size="small"
                    required
                    placeholder="Descrição do processo"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={isLargeScreen ? 4 : 3}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Abertura</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 7.5 : 12}>
                  <MaskStyle>
                    <input
                      type="date"
                      name="end_date"
                      placeholder="dd/mm/yyyy"
                      value={registerDate}
                      onChange={(e) => setRegisterDate(e.target.value)}
                      min="1970-01-01"
                      style={{ width: "100%" }}
                    />
                  </MaskStyle>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={isLargeScreen ? 3 : 3}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Encerramento</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 8 : 12}>
                  <MaskStyle>
                    <input
                      type="date"
                      name="end_date"
                      placeholder="dd/mm/yyyy"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      min="1970-01-01"
                      style={{ width: "100%" }}
                    />
                  </MaskStyle>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={isLargeScreen ? 4 : 6}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Tipo</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 10 : 12}>
                  <Select
                    labelId="show_select_type"
                    id="show_select_type"
                    value={type}
                    size="small"
                    onChange={(event) => setType(event.target.value as string)}
                    fullWidth
                  >
                    <MenuItem value="select" disabled>
                      Selecione:
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {shoppingProcessPhaseType?.map((type) => (
                      <MenuItem key={type.id} value={`${type.id}`}>
                        {type.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={isLargeScreen ? 7 : 6}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Valor</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 9.5 : 12}>
                  <TextField
                    label="10.000.000,00"
                    size="small"
                    disabled
                    value={sumValueTotalItems.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={isLargeScreen ? 4 : 6}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {isLargeScreen && (
                  <Grid item>
                    <FormLabel>Status</FormLabel>
                  </Grid>
                )}
                <Grid item md={isLargeScreen ? 10 : 12}>
                  <Select
                    labelId="show_select_status"
                    id="show_select_status"
                    value={status}
                    size="small"
                    onChange={(event) =>
                      setStatus(event.target.value as string)
                    }
                    fullWidth
                  >
                    <MenuItem value="select" disabled>
                      Selecione:
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {shoppingProccessStatus.map((statusItem) => (
                      <MenuItem key={statusItem.id} value={`${statusItem.id}`}>
                        {statusItem.description}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            {shoppingProcesPhasesSelect?.id &&
            shoppingProcesPhasesSelect?.statusId !== status ? (
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="observations"
                  label={`Observação sobre a alteração do status!`}
                  multiline
                  maxRows={4}
                  value={observationStatusPC}
                  onChange={(e) => setObservationStatusPC(e.target.value)}
                  fullWidth
                />
              </Grid>
            ) : null}
          </Grid>

          <FormLabel sx={{ marginTop: 5 }}>Itens</FormLabel>
          <Divider sx={{ marginY: 2, backgroundColor: palette.primary.main }} />
          <Grid sx={{ marginBottom: 2 }}>
            <ItemCustom>
              <ButtonInclude
                label="Incluir"
                onClick={async () => {
                  setShowButtonClosed(true);
                  if (!shoppingProcesPhasesSelect?.id) {
                    await handleNewSalve(shoppingProcesSelect?.id ?? "");
                  } else {
                    handleSelected(null);
                    setOpenDialogSecondary(true);
                  }
                }}
              />
            </ItemCustom>
          </Grid>

          <Card sx={{ padding: 2, width: "100%" }}>
            {loadingDialog ||
            loadingShoppingProcessType ||
            loadingShoppingProcessPhasesItem ? (
              <CircularLoading />
            ) : shoppingProcessPhasesItems.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                funtionToOrder={(item) =>
                  item?.orderBackend &&
                  setOrderBy({
                    number: item?.orderBackend,
                    order: item?.order === "asc" ? true : false,
                  })
                }
              >
                {shoppingProcessPhasesItems.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      getCompanysContacts(item.supplierId);
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {item.codeProduct}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                      sx={{ width: "15%" }}
                    >
                      {item.descriptionProduct}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                      sx={{ width: "20%" }}
                    >
                      {item?.buyer}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                      sx={{ width: "20%" }}
                    >
                      {item?.supplier}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {item?.paymentCondition}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {item?.unit}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {item?.quantity}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {parseFloat(item?.unitValue)?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {parseFloat(item?.totalValue ?? "0")?.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        Swal.fire({
                          title: "Atenção!",
                          text: "O item será deletado, deseja continuar ? ",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: "Sim, deletar!",
                          cancelButtonText: "Não",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setShoppingProcesPhasesItemSelect(item);
                            handleDelete(item);
                          }
                        })
                      }
                    >
                      <DeleteIcon color="error" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Card>

          <Card sx={{ marginTop: "2%" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Andamento da etapa do processo de compra
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {statusProgress.length === 0 ? (
                  <Empty />
                ) : (
                  <TableComponent
                    header={[
                      {
                        label: "Status",
                      },
                      {
                        label: "Data",
                      },
                      {
                        label: "Usuário Responsável",
                      },
                      {
                        label: "Observação",
                      },
                    ]}
                    page={{
                      page: 0,
                      rowsPerPage: 500,
                      total: statusProgress.length,
                    }}
                    handleChangePage={() => {}}
                    handleChangeRowsPerPage={() => {}}
                  >
                    {statusProgress.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.processoCompraStatus}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.data &&
                            format(new Date(item.data), "dd/MM/yyyy HH:mm")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.usuarioSistemaResponsavel}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.observacao}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableComponent>
                )}
              </AccordionDetails>
            </Accordion>
          </Card>

          <Grid
            display="flex"
            alignItems="center"
            justifyContent="end"
            sx={{ marginTop: 3 }}
          >
            <ButtonDefault
              label="Fechar"
              onClick={() => {
                setOpenDialog(false);
                handleSelectedShoppingProcessPhases(null);
                setShoppingProcessPhasesItems([]);
                getShoppingProcessPhases(`${shoppingProcesSelect?.id}`);
              }}
            />
            {shoppingProcesPhasesSelect?.id ? (
              <ButtonSalveForm
                label="Salvar"
                colorIcon="success"
                type="submit"
                onClick={() => setShowButtonClosed(false)}
              />
            ) : (
              <ButtonSalveForm
                label="Incluir"
                colorIcon="success"
                type="submit"
                onClick={() => setShowButtonClosed(true)}
              />
            )}
          </Grid>
        </DialogContent>
      </form>

      <DialogComponent open={openDialogSecondary} handleClose={() => {}}>
        <ItemStagePurchasingProcess />
      </DialogComponent>
    </>
  );
};

export default StagePurchasingProcess;
