/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import {
  Autocomplete,
  Card,
  FormLabel,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import useParticipatingCompanies from "src/hooks/useAddress copy";
import CircularLoading from "src/components/Loading";
import useLayout from "src/hooks/useLayout";
import ReactInputMask from "react-input-mask";
import SearchIcon from "@mui/icons-material/Search";
import { MaskStyle } from "src/styles/globalStyle";
import useCompany from "src/hooks/useCompany";
import useCompanyContact from "src/hooks/useCompanyContact";
import { ButtonDefault, ButtonInclude } from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import ParticipatingCompanyAdd from "./ParticipatingCompanyAdd";
import DialogComponent from "src/components/Dialog";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { maskCnpj } from "src/functions/text";
import useShoppingProcess from "src/hooks/useShoppingProcess";

const ParticipatingCompanies: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const autocompleteRef = useRef<any>(null);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);

  const { width } = useLayout();
  const {
    loading,
    participatingCompaniesSelect,
    setParticipatingCompaniesSelect,
    contact,
    setContact,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    participatingCompanies,
    getCompanysParticipating,
    handleSelected,
    openDialog,
    setOpenDialog,
    searchCompany,
    setSearchCompany,
    handleNewSalve,
    handleDelete,
    handleUpdate,
  } = useParticipatingCompanies();

  const { shoppingProcesSelect } = useShoppingProcess();

  const {
    companysToSearchBuyer,
    setCompanysToSearchBuyer,
    getCompanyBuyer,
    loading: loadingCompany,
    nameSearch,
    setNameSearch,
    setCnpjSearch,
    setSocialNameSearch,
    getCompanyToCnpj,
    setCitySearch,
    setStateSearch,
    setGroupSearch,
  } = useCompany();

  const {
    getCompanysContacts,
    companysContactToSearch,
    setCompanysContactToSearch,
  } = useCompanyContact();

  const openCompanySearch = () => {
    setNameSearch("");
    setCnpjSearch("");
    setCitySearch("");
    setSocialNameSearch("");
    setStateSearch("");
    setGroupSearch("");
    setParticipatingCompaniesSelect({
      empresasId: "",
      empresasNome: "",
      label: "",
      cnpj: "",
      responsavelId: "",
      responsavelNome: "",
      email: "",
      processoComprasId: "",
    });
  };

  const header = [
    {
      id: "cnpj",
      label: translations[param]["cnpj"],
      isOrder: true,
      order: 1,
    },
    {
      id: "company",
      label: translations[param]["company"],
      isOrder: true,
      order: 2,
    },
    {
      id: "contacts",
      label: translations[param]["contacts"],
      isOrder: true,
      order: 3,
    },
    {
      id: "email",
      label: translations[param]["email"] + translations[param]["contacts"],
      isOrder: true,
      order: 4,
    },
    {
      id: "email",
      label: "Declinou ?",
      isOrder: true,
      order: 5,
    },
  ];

  useEffect(() => {
    setCompanysToSearchBuyer([]);
    setCompanysContactToSearch([]);
    setContact(null);
    setParticipatingCompaniesSelect({
      empresasId: "",
      empresasNome: "",
      label: "",
      cnpj: "",
      responsavelId: "",
      responsavelNome: "",
      email: "",
      processoComprasId: "",
    });
  }, []);

  useEffect(() => {
    getCompanysParticipating(shoppingProcesSelect?.id ?? "");
  }, [page.page]);

  useEffect(() => {
    if (nameSearch.length >= 4) {
      getCompanyBuyer(50);
    } else if (nameSearch.length === 0) {
      getCompanyBuyer(50);
    }
  }, [nameSearch]);

  function containsNumbers(str: string) {
    const regex = /\d/;
    return regex.test(str);
  }

  if (loading) return <CircularLoading />;

  return (
    <>
      <Card sx={{ width: "100%", padding: 2 }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {width >= 1500 ? (
              <Grid item xs={1}>
                <FormLabel>{translations[param]["cnpj"]}</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={width >= 1500 ? 5 : 6}>
              <MaskStyle>
                <ReactInputMask
                  mask="99.999.999/9999-99"
                  name="cnpj"
                  placeholder={translations[param]["cnpj"]}
                  value={participatingCompaniesSelect?.cnpj}
                  onChange={async (e) => {
                    const value = e.target.value?.replace(/\D/g, "");
                    setParticipatingCompaniesSelect({
                      ...participatingCompaniesSelect,
                      cnpj: value,
                    });

                    if (value.length === 14) {
                      const response = await getCompanyToCnpj(value);

                      setParticipatingCompaniesSelect({
                        ...participatingCompaniesSelect,
                        empresasId: response?.id,
                        empresasNome: response?.razaoSocial,
                        label: response?.razaoSocial,
                        cnpj: value,
                      });

                      getCompanysContacts(response?.id);
                    }
                  }}
                />
              </MaskStyle>
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center">
              {width >= 1500 ? (
                <Grid item xs={1.5}>
                  <FormLabel>{`${translations[param]["search"]} `}</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={width >= 1500 ? 10 : 11}>
                <TextField
                  id="value"
                  label={`${translations[param]["search"]} ${translations[param]["company"]}: ${translations[param]["cnpj"]}, ${translations[param]["social_name"]}, ${translations[param]["fantasy_name"]}`}
                  value={searchCompany}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchCompany(value);

                    if (containsNumbers(value)) {
                      setNameSearch("");
                      setSocialNameSearch("");
                      setCnpjSearch(value?.replace(/\D/g, ""));
                    } else {
                      setCnpjSearch("");
                      setNameSearch(value);
                      setSocialNameSearch(value);
                    }
                  }}
                  fullWidth
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSearchCompany("");
                            setCnpjSearch("");
                            setSocialNameSearch("");
                            setNameSearch("");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={0.5}>
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={() => {
                    setOpenAutocomplete(true);
                    autocompleteRef.current?.focus();
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {loadingCompany ? (
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: 5 }}
            >
              <CircularLoading />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} display="flex" alignItems="center">
                {width >= 1500 ? (
                  <Grid item xs={1}>
                    <FormLabel>{`${translations[param]["company"]}`}</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={width >= 1500 ? 11 : 12}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="show_select_buyer_company"
                    options={companysToSearchBuyer}
                    value={participatingCompaniesSelect}
                    open={openAutocomplete}
                    onClose={() => setOpenAutocomplete(false)}
                    getOptionLabel={(option) => {
                      return `${option.label}`;
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          empresasId: value?.id,
                          empresasNome: value?.label,
                          label: value?.label,
                          cnpj: value?.cnpj,
                        });

                        setContact(null);
                        getCompanysContacts(value?.id);
                      } else {
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          empresasId: "",
                          empresasNome: "",
                          label: "",
                          responsavelId: "",
                          responsavelNome: "",
                          cnpj: "",
                        });

                        setContact(null);
                        setCompanysContactToSearch([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        inputRef={autocompleteRef}
                        required
                        onChange={(e) => {
                          setNameSearch(e.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} display="flex" alignItems="center">
                {width >= 1500 ? (
                  <Grid item xs={1}>
                    <FormLabel>{`${translations[param]["responsible"]}`}</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={width >= 1500 ? 11 : 12}>
                  <Autocomplete
                    disablePortal
                    id="show_select_administrator_responsible"
                    options={companysContactToSearch}
                    value={contact}
                    getOptionLabel={(option) => {
                      return `${option?.label}`;
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setContact({
                          ...value,
                          responsavelId: value.id,
                          responsavelNome: value.label,
                        });
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          email: value?.email,
                        });
                      } else {
                        setContact(null);
                        setParticipatingCompaniesSelect({
                          ...participatingCompaniesSelect,
                          email: "",
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione:" size="small" />
                    )}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} display="flex" alignItems="center">
            {width >= 1500 ? (
              <Grid item xs={1}>
                <FormLabel>{`${translations[param]["email"]} ${translations[param]["responsible"]}`}</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={width >= 1500 ? 11 : 12}>
              <TextField
                id="email"
                disabled
                value={
                  contact !== null
                    ? participatingCompaniesSelect?.email
                    : translations[param]["email"]
                }
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="end"
            sx={{ marginTop: 5 }}
          >
            <ButtonDefault
              label="Pesquisar Empresas"
              onClick={async () => {
                openCompanySearch();
                await getCompanyBuyer(50);

                setOpenDialog(true);
              }}
            />
            {participatingCompaniesSelect?.id ? (
              <ButtonInclude label="Salvar" onClick={handleUpdate} />
            ) : (
              <ButtonInclude
                label="Incluir"
                onClick={() => handleNewSalve(`${shoppingProcesSelect?.id}`)}
              />
            )}
          </Grid>
        </Grid>
      </Card>

      <Grid sx={{ marginTop: 5 }}>
        <FormLabel>{translations[param]["participating_companies"]}</FormLabel>
      </Grid>

      <Card sx={{ width: "100%", padding: 2 }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          {loading ? (
            <CircularLoading />
          ) : participatingCompanies.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {participatingCompanies.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    await getCompanysContacts(item?.empresasId);
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelected(item);
                    }}
                  >
                    {item.cnpj ? maskCnpj(item.cnpj) : "Não informado"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelected(item);
                    }}
                  >
                    {item.nomeFantasia}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelected(item);
                    }}
                  >
                    {item.nomeResponsavelComprador}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelected(item);
                    }}
                  >
                    {item.emailResponsavelComprador}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelected(item);
                    }}
                  >
                    {item?.declinouParticipacao ? (
                      <CheckOutlinedIcon color="success" />
                    ) : (
                      <CloseOutlinedIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      Swal.fire({
                        title: translations[param]["attention"],
                        text: "Deseja remover o participante ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: palette.primary.main,
                        cancelButtonColor: palette.error.main,
                        confirmButtonText: translations[param]["yes"],
                        cancelButtonText: translations[param]["no"],
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDelete(item.id);
                        }
                      });
                    }}
                  >
                    <DeleteIcon color="error" />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Grid>
      </Card>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <ParticipatingCompanyAdd />
      </DialogComponent>
    </>
  );
};

export default ParticipatingCompanies;
